import React, { ReactNode, useEffect, useReducer } from 'react'

import { useAppStore } from '../App'
import {
  actionAddCartBundle,
  actionAddCartProduct,
  actionCreateClonedCartFromOrder,
  actionEmptyCustomerCart,
  actionGetCartSummary,
  actionGetCustomerCart,
  actionRemoveCartBundle,
  actionRemoveCartProduct,
  actionReplaceBundle,
  actionReplaceProduct,
} from './actions'
import { CartContext, CartContextInitialState, DispatchContext } from './context'
import reducer from './reducer'

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const {
    state: { currentStore },
  } = useAppStore()
  const [state, dispatch] = useReducer(reducer, {
    ...CartContextInitialState,
    cart: { ...CartContextInitialState.cart, storeId: Number(currentStore?.id) ?? 1 },
  })

  useEffect(() => {
    if (currentStore?.id) {
      actionGetCustomerCart(dispatch, currentStore.id)()
    }
  }, [currentStore?.id])

  return (
    <CartContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </CartContext.Provider>
  )
}

export const useCart = () => {
  const state = React.useContext(CartContext)
  const dispatch = React.useContext(DispatchContext)

  if (state === undefined || dispatch === undefined) {
    throw new Error('useNewCart must be used within a NewCartProvider')
  }

  const substractProductQuantity = ({
    sku,
    quantity = 1,
    fromScreen,
    isBundle,
  }: {
    sku: string
    quantity?: number
    fromScreen?: number
    isBundle?: boolean
  }) => {
    const bundle = isBundle ? state.cart.bundles.find((bundle) => bundle.sku === +sku) : null
    const product = !isBundle ? state.cart.items.find((item) => item.sku === +sku) : null

    if (bundle) {
      // eslint-disable-next-line prettier/prettier
      actionReplaceBundle(
        dispatch,
        state.cart.storeId.toString(),
      )({
        bundleQuantity: bundle.bundleQuantity - quantity,
        sku: bundle.sku,
      })
      return
    }

    if (product) {
      // eslint-disable-next-line prettier/prettier
      actionReplaceProduct(
        dispatch,
        state.cart.storeId.toString(),
      )({
        bottleAmount: 0,
        boxAmount: product.boxAmount - quantity,
        sku: product.sku,
        fromScreen,
      })
    }
  }

  const addProductQuantity = ({
    sku,
    quantity = 1,
    fromScreen,
    isBundle,
  }: {
    sku: string
    quantity?: number
    fromScreen?: number
    isBundle?: boolean
  }) => {
    const bundle = isBundle ? state.cart.bundles.find((bundle) => bundle.sku === +sku) : null
    const product = !isBundle ? state.cart.items.find((item) => item.sku === +sku) : null

    if (bundle) {
      // eslint-disable-next-line prettier/prettier
      actionReplaceBundle(
        dispatch,
        state.cart.storeId.toString(),
      )({
        bundleQuantity: bundle.bundleQuantity + quantity,
        sku: bundle.sku,
      })
      return
    }

    if (product) {
      // eslint-disable-next-line prettier/prettier
      actionReplaceProduct(
        dispatch,
        state.cart.storeId.toString(),
      )({
        bottleAmount: 0,
        boxAmount: product.boxAmount + quantity,
        sku: product.sku,
        fromScreen,
      })
    }
  }

  return {
    state,
    substractProductQuantity,
    addProductQuantity,
    addProduct: actionAddCartProduct(dispatch, state.cart.storeId.toString()),
    removeProduct: actionRemoveCartProduct(dispatch, state.cart.storeId.toString()),
    replaceProduct: actionReplaceProduct(dispatch, state.cart.storeId.toString()),
    addBundle: actionAddCartBundle(dispatch, state.cart.storeId.toString()),
    removeBundle: actionRemoveCartBundle(dispatch, state.cart.storeId.toString()),
    replaceBundle: actionReplaceBundle(dispatch, state.cart.storeId.toString()),
    emptyCustomerCart: actionEmptyCustomerCart(dispatch, state.cart.storeId.toString()),
    getCustomerCart: actionGetCustomerCart(dispatch, state.cart.storeId.toString()),
    getCartSummary: actionGetCartSummary(dispatch, state.cart.storeId.toString()),
    createRepeatedCart: actionCreateClonedCartFromOrder(dispatch, state.cart.storeId.toString()),
    getTotalQuantity: () =>
      state.cart.items.reduce<number>((total, item) => total + item.boxAmount, 0) +
      state.cart.bundles.reduce<number>((total, item) => total + item.bundleQuantity, 0),
  }
}
